export const comparisonUrlParam = "comparison";
export const connectUrlParam = "connect";
export const organizationIdUrlParam = "organizationId";
export const filterSplitUrlParam = "filterSplit";
export const distributionLevel1UrlParam = "distributionLevel1";
export const distributionLevel2UrlParam = "distributionLevel2";
export const optionIdUrlParam = "optionId";
export const trendIntervalUrlParam = "trendInterval";
export const yearUrlParam = "year";
export const episodeUrlParam = "episode";
export const timeRangeUrlParam = "timeRange";

export const allAllowedUrlParams = [
    comparisonUrlParam,
    connectUrlParam,
    organizationIdUrlParam,
    filterSplitUrlParam,
    distributionLevel1UrlParam,
    distributionLevel2UrlParam,
    optionIdUrlParam,
    trendIntervalUrlParam,
    yearUrlParam,
    episodeUrlParam,
    timeRangeUrlParam,
];
