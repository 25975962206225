import { inject } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Route,
    UrlSegment,
    Router,
    CanActivateFn,
    CanActivateChildFn,
    CanMatchFn,
} from "@angular/router";

import { tap, take } from "rxjs/operators";

import { Auth0AuthorizationService } from "./auth0-authorization.service";
import { organizationIdUrlParam, trendIntervalUrlParam } from "@codman/shared/types";

export const auth0GuardCanActivate: CanActivateFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const auth = inject(Auth0AuthorizationService);

    return auth.isAuthenticated$.pipe(
        take(1),
        tap(loggedIn => {
            if (!loggedIn) {
                auth.login(state.url);
            }
        }),
    );
};

export const auth0GuardCanActivateChild: CanActivateChildFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    return auth0GuardCanActivate(next, state);
};

export const auth0GuardCanLoad: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
    const auth = inject(Auth0AuthorizationService);
    const router = inject(Router);

    return auth.isAuthenticated$.pipe(
        take(1),
        tap(loggedIn => {
            if (!loggedIn) {
                const fullPath = segments.reduce((path, currentSegment) => {
                    return `${path}/${currentSegment.path}`;
                }, "");

                const navigation = router.getCurrentNavigation();
                const organizationId =
                    navigation?.extractedUrl.queryParamMap.get(organizationIdUrlParam);
                const trendInterval =
                    navigation?.extractedUrl.queryParamMap.get(trendIntervalUrlParam);

                if (organizationId) {
                    localStorage.setItem(organizationIdUrlParam, organizationId);
                }

                if (trendInterval) {
                    localStorage.setItem(trendIntervalUrlParam, trendInterval);
                } else {
                    localStorage.removeItem(trendIntervalUrlParam);
                }

                auth.login(fullPath);
            }
        }),
    );
};
